/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import Blogs from '../../components/blogs';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";

/**
 * Blog Template Page.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Archive = (props) => {

	const { pageContext: { postNodes, categories, form, seo, uri, stickyPostNodes, stickyPostIds, hidePostNodeIds, pageInfo } } = props;
	const openGraphImage = getOgImage(seo);
	const title = 'Blog';

	let initialPosts = stickyPostNodes.concat(postNodes.slice(0, 11));

	if (0 === stickyPostIds.length) {
		initialPosts = postNodes.slice(0, 12);
	}
	return (
		<Layout title={title} uri={uri} className="archive" >
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<Blogs
				initialPosts={initialPosts}
				allPosts={postNodes.slice(11)}
				categories={categories}
				category={{}}
				form={form}
				tagName="Blog_Submit"
				tagParent="BLOG"
				hidePostNodeIds={hidePostNodeIds}
				pageInfo={pageInfo}
			/>
		</Layout>
	)
};

Archive.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
}

export default Archive;
